body,
html {
	margin: 0;
	box-sizing: border-box;
	font-family: "Open Sans", sans-serif;
	line-height: 1.4;
	font-weight: 300;
	font-size: 1rem;
	scroll-behavior: smooth;
	letter-spacing: 0.015em;
	min-height: 100%;
}

p,
div {
	line-height: 1.6;
	font-family: "Open Sans", sans-serif;
}

a {
	color: #009cac;
}

// ========== fonts ==========
.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
	font-family: "Bitter", sans-serif;
	font-weight: 400;
	letter-spacing: 0.1rem;
	color: "#263739";
}
@media (max-width: 991px) {
	.h1,
	h1 {
		font-size: 1.4rem;
		color: "#263739";
	}
}

.scrollX-no-scrollbar::-webkit-scrollbar {
	display: none;
}

ul {
	padding-left: 20px;
}

.show-scrollbar-form {
	overflow-y: auto;
	border: 1px solid whitesmoke;
	border-radius: 5px;
	max-height: 470px;
	width: 100%;
	line-height: 1em;
}

.show-scrollbar-form::-webkit-scrollbar {
	-webkit-appearance: none;
}

.show-scrollbar-form::-webkit-scrollbar:vertical {
	width: 11px;
}

.show-scrollbar-form::-webkit-scrollbar:horizontal {
	height: 11px;
}

.show-scrollbar-form::-webkit-scrollbar-thumb {
	border-radius: 8px;
	border: 2px solid white; /* should match background, can't be transparent */
	background-color: rgba(0, 0, 0, 0.5);
}

// nav dropdown
.dropdown {
	position: relative;
	display: inline-block;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: #042c33;
	min-width: 270px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	font-size: 1rem;
}

.dropdown:hover .dropdown-content {
	display: block;
}

// Digishop Logo
#whereToBuy > div > div > div:nth-child(6) > a > div > svg > g > path:nth-child(2) {
	fill: #fff;
}
